import PageContainer from "../PageContainer";
import GalleryHeaderSkeleton from "./GalleryHeaderSkeleton";
import NumericCollectionSkeleton from "./NumericCollectionSkeleton";

const DynamicPageSkeleton: React.FC = () => (
  <PageContainer>
    <GalleryHeaderSkeleton />
    <NumericCollectionSkeleton />
  </PageContainer>
);

export default DynamicPageSkeleton;
