import { styled } from "@mui/material";
import ArticleSkeleton, { TextContainer } from "./ArticleSkeleton";
import ImageSkeleton from "./ImageSkeleton";

const GalleryHeaderSkeletonWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gap: theme.spacing(4),

  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(8),
  },

  span: {
    width: "100%",
    height: "auto",
  },

  ["& > span:first-of-type"]: {
    gridColumn: "12 span",

    [theme.breakpoints.down("md")]: {
      width: "calc(100% + 32px)",
      marginLeft: theme.spacing(-4),
    },

    [theme.breakpoints.up("lg")]: {
      gridColumn: "8 span",
      gridRow: "2 span",
    },
  },

  ["span:last-child"]: {
    width: "50%",
    display: "block",
  },

  ["& > div"]: {
    gridColumn: "6 span",
    flexDirection: "column",
    justifyContent: "center",

    [theme.breakpoints.up("lg")]: {
      gridColumn: "4 span",
    },
  },

  [`${TextContainer}`]: {
    paddingLeft: 0,

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const GalleryHeaderSkeleton: React.FC = () => (
  <GalleryHeaderSkeletonWrapper>
    <ImageSkeleton aspectRatio="16:9" />
    <ArticleSkeleton showCategory />
    <ArticleSkeleton showCategory />
  </GalleryHeaderSkeletonWrapper>
);

export default GalleryHeaderSkeleton;
