import type { NextPage, NextPageContext } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { EventLog } from "@4tn/webx-analytics";
import { useQuery } from "@tanstack/react-query";
import PageHeader from "@common/PageHeader/PageHeader";
import DynamicContent from "@pageContent/DynamicContent";
import OopsPage from "@pageContent/OopsPage";
import StructuredData from "@pageContent/StructuredData";
import PageContainer from "@pageContent/common/PageContainer/PageContainer";
import DynamicPageSkeleton from "@pageContent/common/Skeletons/DynamicPageSkeleton";
import { tableNames } from "@constants/consts";
import { createRedirectResponse } from "@utils/common/createRedirectResponse";
import { useHostConfig } from "@utils/common/getHostConfig";
import { useNoticeError } from "@utils/common/newRelic";
import usePageView from "@utils/common/usePageView";
import { fetchByEndpoint, fetchLinks, paginatedFetch } from "@utils/pageContent/fetchData";

const getPage = async (slug: string, pageNumber: number): Promise<Page | CategoryEntry | null> => {
  let pageEndpoint = `/api/page/${slug}`;
  if (EventLog.didEventOccurred("header_click", "link")) {
    pageEndpoint += `?timestamp=${new Date().getTime()}`;
  }

  const [page, category] = await Promise.all([
    fetchByEndpoint<Page>(pageEndpoint),
    slug !== "home" ? fetchByEndpoint<Page>(`/api/category/${slug}`) : null,
  ]);

  if (category) {
    const paginatedCategoryCollection = category.items.find(
      (item) => item.contentType === "paginatedCategoryCollection"
    ) as PaginatedCategoryCollection | undefined;

    if (paginatedCategoryCollection) paginatedCategoryCollection.page = pageNumber;
  }

  return page || category;
};

const DynamicPage: NextPage<{ initialPage?: Page | CategoryEntry }> = ({ initialPage }) => {
  const { query } = useRouter();
  const { outletName } = useHostConfig();

  const slugs = Array.isArray(query.slugs) ? query.slugs : [];
  const slug = slugs[slugs.length - 1];

  const pageNumber = query.page ? Number(query.page) : 1;

  const pageQueryKey = ["page", slug, pageNumber];

  const {
    isLoading,
    isFetching,
    data: page,
    isError,
    error,
  } = useQuery({
    queryKey: pageQueryKey,
    queryFn: () => getPage(slug || "home", pageNumber),
    initialData: initialPage,
  });

  useNoticeError(error, { queryKey: pageQueryKey.join(",") });
  usePageView({ isLoading, error, data: page });

  if ((isFetching || isLoading) && !initialPage) return <DynamicPageSkeleton />;
  if (!page) return <OopsPage is404={!isError} />;

  const { title, header, items, structuredData } = page;

  return (
    <>
      <Head>
        <title>{`${title} | ${outletName}`}</title>
        <meta property="og:title" content={`${title} | ${outletName}`} />
        <meta property="og:type" content="website" />
      </Head>
      <StructuredData structuredData={structuredData} />
      {header && <PageHeader {...header} />}
      <PageContainer id="page-container">
        {items?.map((item) => <DynamicContent key={item.id} {...item} />)}
      </PageContainer>
    </>
  );
};

DynamicPage.getInitialProps = async ({
  req,
  res,
  query,
  dynamoDBUtils,
}: NextPageContext & { dynamoDBUtils: { getItemBySlug: <T>(tableName: string, slug: string) => T } }) => {
  if (!res || !req) return {};

  const slugs = Array.isArray(query.slugs) ? query.slugs : [];

  const lastSlug = slugs.length ? slugs[slugs.length - 1] : "home";
  try {
    const [page, category] = await Promise.all([
      dynamoDBUtils.getItemBySlug<Page>(tableNames.page, lastSlug),
      lastSlug !== "home" ? dynamoDBUtils.getItemBySlug<CategoryEntry>(tableNames.category, lastSlug) : null,
    ]);

    const initialPage = page || category;

    if (page) {
      const categoryBasedCollections = page.items.filter((item) => item.contentType === "categoryBasedCollection");
      await Promise.all(
        categoryBasedCollections.map(async (categoryBasedCollection) => {
          try {
            const response = await paginatedFetch<PageLink>({
              endpoint: categoryBasedCollection.endpoint,
              page: 1,
              limit: 10,
            });
            categoryBasedCollection.initialData = response.items;
          } catch (error) {
            console.log(error);
          }
          return categoryBasedCollections;
        })
      );
    }

    if (category) {
      const paginatedCategoryCollection = category.items.find(
        (item) => item.contentType === "paginatedCategoryCollection"
      ) as PaginatedCategoryCollection | undefined;

      if (paginatedCategoryCollection) {
        const limit = 16;
        const pageNumber = query.page ? Number(query.page) : 1;
        const [currentUrl] = req.url?.split("?") || [];
        if (pageNumber === 0) return createRedirectResponse({ res, query, url: currentUrl });
        paginatedCategoryCollection.initialData = await fetchLinks({
          endpoint: paginatedCategoryCollection.articleEndpoint,
          page: pageNumber,
          limit,
        });
        paginatedCategoryCollection.page = pageNumber;
      }
    }

    if (initialPage) {
      const [currentUrl] = req.url?.split("?") || [];
      if (initialPage.url !== currentUrl) {
        return createRedirectResponse({ res, query, url: initialPage.url });
      }
      return { initialPage };
    }

    // check whether slug matches article
    const article = await dynamoDBUtils.getItemBySlug<Article>(tableNames.article, lastSlug);
    if (article) {
      return createRedirectResponse({ res, query, url: article.redirectUrl || article.url });
    }

    res.statusCode = 404;
    return {};
  } catch (error) {
    console.log(error);
    res.statusCode = 500;
  }
  return {};
};

export default DynamicPage;
