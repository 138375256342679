import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { ItemsContainer } from "../InfiniteCollection/InfiniteCollection";
import { TextContainer } from "./ArticleSkeleton";
import InfiniteCollectionSkeleton from "./InfiniteCollectionSkeleton";

const lastThreeDirectChilds = "& > div:nth-of-type(n + 3)";
const directDivChild = "& > div";
const articleCategoryField = "& > div > span:last-child";

const NumericCollectionSkeletonWrapper = styled("div")(({ theme }) => ({
  [articleCategoryField]: {
    display: "none",
  },

  [`${ItemsContainer}`]: {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gap: theme.spacing(4),
      gridTemplateColumns: "repeat(12, 1fr)",

      [lastThreeDirectChilds]: {
        gridColumn: "4 span",
      },

      [directDivChild]: {
        gridColumn: "6 span",

        [`${TextContainer}`]: {
          display: "none",
        },
      },
    },

    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(10, 1fr)",

      [directDivChild]: {
        gridColumn: "2 span",
      },

      [lastThreeDirectChilds]: {
        gridColumn: "2 span",

        [`${AspectRatioContainer}`]: {
          paddingBottom: "100%",
        },
      },
    },
  },
}));

const NumericCollectionSkeleton: React.FC = () => (
  <NumericCollectionSkeletonWrapper>
    <InfiniteCollectionSkeleton title="numeric-collection-skeleton" limit={5} />
  </NumericCollectionSkeletonWrapper>
);

export default NumericCollectionSkeleton;
