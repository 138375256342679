import { FC, useEffect, useRef, useState } from "react";
import getConfig from "next/config";
import PageContainer from "@pageContent/common/PageContainer";
import * as Styled from "./PageHeader.styled";

const { publicRuntimeConfig } = getConfig();

export interface PageHeaderProps {
  title: string;
  subtitle?: string;
  imageUrl: string;
}

const imageHeaderLoader = ({ src, width, quality }: { src: string; width: number; quality?: number }) => {
  const [image, query] = src.split("?");
  const searchParams = new URLSearchParams(query);
  const aspectRatio = Number(searchParams.get("ap"));
  const height = Math.round(aspectRatio * width);

  const params = ["f_auto", "c_fill", "g_faces:auto", "dpr_2.0", `h_${height}`, `w_${width}`, `q_${quality || "auto"}`];
  return `${publicRuntimeConfig.cloudinaryDomain}/image/fetch/${params.join(",")}/${image}`;
};

const PageHeader: FC<PageHeaderProps> = ({ title, subtitle, imageUrl }) => {
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const [aspectRatio, setAspectRatio] = useState(0.429);

  useEffect(() => {
    const handleResize = () => {
      if (!imageContainerRef.current) return;
      const { offsetHeight, offsetWidth } = imageContainerRef.current;
      setAspectRatio(Math.round((offsetHeight / offsetWidth) * 1000) / 1000);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [imageContainerRef]);

  return (
    <Styled.PageContainerWrapper>
      <Styled.ImageContainer ref={imageContainerRef}>
        <Styled.HeaderImage
          src={`${imageUrl}?ap=${aspectRatio}`}
          alt={title}
          loader={imageHeaderLoader}
          fill
          sizes="(max-width: 900px) 100vw, 50vw"
        />
      </Styled.ImageContainer>
      <PageContainer>
        <Styled.PageHeaderTextContentWrapper>
          <Styled.PageHeaderTitle>{title}</Styled.PageHeaderTitle>
          {subtitle && <Styled.PageHeaderSubtitle>{subtitle}</Styled.PageHeaderSubtitle>}
        </Styled.PageHeaderTextContentWrapper>
      </PageContainer>
    </Styled.PageContainerWrapper>
  );
};

export default PageHeader;
