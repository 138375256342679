import Image from "next/image";
import { styled } from "@mui/material";
import { colors, fonts, gradients } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const PageContainerWrapper = styled("div")(({ theme }) => ({
  backgroundColor: colors.header,
  marginBottom: theme.spacing(8),
  position: "relative",
  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(10),
  },
}));

export const PageHeaderTextContentWrapper = styled("div")(({ theme }) => ({
  paddingBottom: 10,

  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 2, 2.5),
  },
  [theme.breakpoints.down("md")]: {
    position: "relative",
    zIndex: zIndex.base,
    top: -30,
  },
  [theme.breakpoints.up("md")]: {
    width: "calc(50% - 8px)",
    padding: theme.spacing(13.25, 0), // design uses fixed height, might be better to use simple padding instead
  },
  [theme.breakpoints.up("xl")]: {
    width: "calc(50% - 8px)",
    padding: theme.spacing(16.5, 0),
  },
}));

export const ImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  paddingBottom: "42.86%", // Aspect Radio 21:9
  "&:after": {
    content: "''",
    position: "absolute",
    bottom: 0,
    left: 0,
    height: 80,
    width: "100%",
    background: gradients.fadePageHeaderBottomTop,
  },

  [theme.breakpoints.up("md")]: {
    position: "absolute",
    width: "50%",
    paddingBottom: 0,
    right: 0,
    top: 0,
    bottom: 0,
    "&:after": {
      right: "inherit",
      width: 150,
      height: "100%",
      background: gradients.fadePageHeaderLeftRight,
    },
  },
}));

export const HeaderImage = styled(Image)({
  objectFit: "cover",
});

export const PageHeaderTitle = styled("h1")(({ theme }) => ({
  font: fonts.header2,
  color: colors.neutral0,
}));

export const PageHeaderSubtitle = styled("p")(({ theme }) => ({
  paddingTop: theme.spacing(2),
  font: fonts.bodyMedium,
  color: colors.neutral0,
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(2.5),
  },
}));
